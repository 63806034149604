import { HolFunction } from '../../../common/models/hol-function';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { OclOptionsService } from '../../services/ocl-options-service/ocl-options.service';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { OclManagersModalComponent } from '../../modals/ocl-managers-modal/ocl-managers-modal.component';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { Subscription } from 'rxjs';
import { HolManagerFunction, HolManagersSelectors } from '../../../common/store/hol-managers.selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ocl-managers-box',
  templateUrl: './ocl-managers-box.component.html',
  styleUrls: ['./ocl-managers-box.component.scss'],
})
export class OclManagersBoxComponent implements OnInit, OnDestroy {
  @Input() isReadOnly: boolean;

  managersTitle = '';
  managerFunctions: HolManagerFunction[] = [];
  private managerFunctionsSub: Subscription;
  holFunctions: HolFunction[] = [];
  private holFunctionsSub: Subscription;

  protected readonly translate = inject(TranslateService);

  constructor(
    private optionsService: OclOptionsService,
    private dialog: MatDialog,
    public moduleConfig: ModuleConfigService,
    private userFunctionSelectors: HolManagersSelectors,
  ) {
    this.managersTitle = this.optionsService.getManagersTitle()
      ? this.optionsService.getManagersTitle()
      : this.translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.MANAGERS.TITLE');
  }

  ngOnInit() {
    this.managerFunctionsSub = this.userFunctionSelectors.managersFunctions.subscribe(mfs => {
      this.managerFunctions = mfs;
    });
    this.holFunctionsSub = this.userFunctionSelectors.holFunctions.subscribe(f => {
      this.holFunctions = f;
    });
  }

  openManagerModal(ev, item) {
    if (!this.isReadOnly) {
      this.dialog.open(OclManagersModalComponent, {
        data: {
          managerFunction: item,
          holFunctions: this.holFunctions,
        },
      });
    }
  }

  isOnDutyForLessThan24Hours(onDutyUpdateAt) {
    return moment(onDutyUpdateAt).add(24, 'hours').isAfter(moment());
  }

  holderSince(onDutyUpdateAt) {
    const duration = moment.duration(moment().diff(moment(onDutyUpdateAt)));
    return moment.utc(duration.as('ms')).format('HH[h]mm');
  }

  isElapsed(minutesBeforeAlert, onDutyUpdateAt) {
    if (minutesBeforeAlert || minutesBeforeAlert === 0) {
      return moment().subtract(minutesBeforeAlert, 'minutes').isAfter(moment(onDutyUpdateAt));
    }
    return false;
  }

  isBetween(minutes, expiredAt) {
    if (expiredAt) {
      const isBefore = moment(expiredAt).subtract(minutes, 'minutes').isBefore(moment());
      const isAfter = moment(expiredAt).isAfter(moment());
      return isBefore && isAfter;
    }
  }

  isWithinADay(currentFunction) {
    return moment.duration(moment(currentFunction.expiredAt).diff(moment(currentFunction.updatedAt))).asHours() <= 24;
  }

  isOnSameDay(expiredAt) {
    return moment(expiredAt).isSame(moment(), 'days');
  }

  ngOnDestroy(): void {
    this.managerFunctionsSub.unsubscribe();
    this.holFunctionsSub.unsubscribe();
  }
}
