import { CrisisService } from '../../../common/services/crisis/crisis.service';
import { EclCrisis } from '../../models/ecl-crisis';
import { BehaviorSubject } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import moment from 'moment';
import { EclCrisisStoreManager } from '../../store/crisis/crisis.store-manager';
import { EclMailService } from '../ecl-mail-service/ecl-mail.service';
import { EclSmsService } from '../ecl-sms-service/ecl-sms.service';
import { EclTagsService } from '../ecl-tags-service/ecl-tags.service';
import { EclFunctionService } from '../ecl-function-service/ecl-function-service';
import { HolCrisisParameter, HolCrisisParameterValue } from '../../../common/models/hol-crisis-parameter';
import { EclLogbook } from '../../models/ecl-logbook';
import { EclSummary } from '../../models/ecl-summary';
import { EclDecisionService } from '../ecl-decision-service/ecl-decision.service';
import { EclDecision } from '../../models/ecl-decision';
import { AclFilterRole, RolesService } from '../../../common/services/roles.service';
import { HolObject } from '../../../common/models/hol-object';
import { EclFunctionStoreManager } from '../../store/function/function.store-manager';
import { HolUser } from '../../../common/models/hol-user.model';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EclCrisisService extends CrisisService<EclCrisis> {
  private ParseEclCrisis = Parse.Object.extend('ECLCrisis');
  private ParseEclCrisisType = Parse.Object.extend('ECLCrisisType');
  private ParseHolCrisisParameterValue = Parse.Object.extend('HOLCrisisParameterValue');
  private ParseEclUserFunction = Parse.Object.extend('ECLUserFunction');
  private readonly ParseCrisisTypeParameter = Parse.Object.extend('ECLCrisisTypeParameter');
  private ParseUser = Parse.Object.extend('_User');

  constructor(
    protected requestService: RequestService,
    private eclCrisisStoreManager: EclCrisisStoreManager,
    private eclFunctionStoreManager: EclFunctionStoreManager,
    private eclMailService: EclMailService,
    private eclSmsService: EclSmsService,
    private eclTagService: EclTagsService,
    private eclfunctionService: EclFunctionService,
    private eclDecisionService: EclDecisionService,
    private roleService: RolesService,
    @Inject('$translate') private readonly $translate,
  ) {
    super(requestService);
    this.ParseCrisis = Parse.Object.extend('ECLCrisis');
  }

  async getCurrentOrLastCrisis(): Promise<EclCrisis | undefined> {
    // 1st. Search the last crisis opened (inProgress == true OR isInPreparation = true)
    const openedQuery = new Parse.Query(this.ParseCrisis);
    openedQuery.equalTo('inProgress', true);
    openedQuery.descending('createdAt');
    const inPreparationQuery = new Parse.Query(this.ParseCrisis);
    inPreparationQuery.equalTo('isInPreparation', true);
    const openedOrInPreparationQuery = Parse.Query.or(openedQuery, inPreparationQuery);
    openedOrInPreparationQuery.descending('createdAt');

    let result = await openedOrInPreparationQuery.first();
    // 2nd. If not found (that means all the crisis are closed now), take the latest crisis created on timeline (by createdAt field)
    if (!result) {
      const lastQuery = new Parse.Query(this.ParseCrisis);
      lastQuery.descending('createdAt');

      result = await lastQuery.first();
    }
    if (!result) {
      return undefined;
    } else {
      const newCrisi = this.newCrisisObject(result);
      const crisi = await this.afterGet([newCrisi]);
      return crisi[0];
    }
  }

  async getLastCrisis(): Promise<EclCrisis | undefined> {
    const lastQuery = new Parse.Query(this.ParseCrisis);
    lastQuery.descending('createdAt');

    const result = await lastQuery.first();
    if (!result) {
      return undefined;
    } else {
      const newCrisi = this.newCrisisObject(result);
      const crisi = await this.afterGet([newCrisi]);
      return crisi[0];
    }
  }

  public async lastUpdatedCrisisFromPolling(parseObject: Parse.Object[]) {
    const result = parseObject ? parseObject.map(parseObject => this.newCrisisObject(parseObject)) : [];
    const crisis = await this.afterGet(result);
    this.eclCrisisStoreManager.updateCrisisFromDashboardPolling(crisis);
  }

  public fetchNewData(lastWanted: Date) {
    if (lastWanted) {
      return this.getAllUpdateAfterLastPolling(lastWanted).then(crisis => {
        this.eclCrisisStoreManager.updateCrisisFromDashboardPolling(crisis);
      });
    }
    return this.getAll().then(async crisis => {
      // STORE
      this.eclCrisisStoreManager.initCrisisFromDashboardPolling(crisis);
    });
  }

  public async getAllUpdateAfterLastPolling(lastWanted: Date): Promise<EclCrisis[]> {
    const lastUpdatedCrisis = new Parse.Query(this.ParseCrisis);
    lastUpdatedCrisis.include('ACL');
    lastUpdatedCrisis.include('crisisType');
    lastUpdatedCrisis.include('createdBy');
    lastUpdatedCrisis.descending('createdAt');
    lastUpdatedCrisis.greaterThanOrEqualTo('updatedAt', lastWanted);
    const parseResult = await this.requestService.performFindAllQuery(lastUpdatedCrisis);
    const result = parseResult ? parseResult.map(parseObject => this.newCrisisObject(parseObject)) : [];

    return await this.afterGet(result);
  }

  public async openClosedCrisis(crisis: EclCrisis, withNotification: boolean): Promise<EclCrisis> {
    const parseObject = new this.ParseEclCrisis({ id: crisis.objectId });
    parseObject.set('inProgress', true);
    parseObject.set('closedAt', null);
    if (withNotification) {
      // not await to be faster on front
      this.eclMailService.sendRepoenCrisisEmails(crisis).then();
      this.eclSmsService.sendReopenCrisisSms(crisis).then();
    }

    await this.automaticDecision(crisis, true);

    return await this.requestService.performSaveQuery(parseObject).then(parseData => {
      const bufferCrisis: EclCrisis = this.newCrisisObject(parseData);
      return bufferCrisis;
    });
  }

  public async closeEclCrisis(crisis: EclCrisis, withNotification: boolean): Promise<EclCrisis> {
    const parseObject = new this.ParseEclCrisis({ id: crisis.objectId });
    parseObject.set('isInPreparation', false);
    parseObject.set('inProgress', false);
    parseObject.set('closedAt', moment.utc().toDate());
    if (withNotification) {
      // not await to be faster on front
      this.eclMailService.sendCloseCrisisMail(crisis).then();
      this.eclSmsService.sendCloseCrisisSms(crisis).then();
    }

    await this.automaticDecision(crisis, false);

    return await this.requestService.performSaveQuery(parseObject).then(parseData => {
      const bufferCrisis: EclCrisis = this.newCrisisObject(parseData);
      return bufferCrisis;
    });
  }

  public async getAllOpenCrisis(): Promise<EclCrisis[]> {
    const openedQuery = new Parse.Query(this.ParseCrisis);
    openedQuery.equalTo('inProgress', true);
    const result = await this.requestService.performFindAllQuery(openedQuery);
    if (!result || result.length == 0) {
      return [];
    } else {
      return result.map(parseObject => this.newCrisisObject(parseObject));
    }
  }

  protected newCrisisObject(parser: Parse.Object): EclCrisis {
    return new EclCrisis(parser);
  }

  protected beforeSave(inputCrisis: Partial<EclCrisis>, parseObject: Parse.Object) {
    parseObject.set('crisisType', new this.ParseEclCrisisType({ id: inputCrisis.type.objectId }));
    parseObject.set('functionsToNotify', inputCrisis.type.functionsToNotify.join('|'));
    parseObject.set('closedAt', inputCrisis.closedAt);
  }

  protected afterSave(inputCrisis: Partial<EclCrisis>, savedCrisis: EclCrisis): EclCrisis {
    return super.afterSave(inputCrisis, savedCrisis);
  }

  protected beforeClose(inputCrisis: Partial<EclCrisis>, parseObject: Parse.Object) {
    parseObject.set('closedAt', moment.utc().toDate());
  }

  protected async afterClose(inputCrisis: EclCrisis, savedCrisis: EclCrisis): Promise<EclCrisis> {
    await this.eclMailService.sendCloseCrisisMail(savedCrisis);
    await this.eclSmsService.sendCloseCrisisSms(savedCrisis);
    const crisis = await this.afterGet([savedCrisis]);

    await this.automaticDecision(savedCrisis, false);
    return crisis[0];
  }

  protected async afterActivate(inputCrisis: EclCrisis, savedCrisis: EclCrisis): Promise<EclCrisis> {
    return super.afterActivate(inputCrisis, savedCrisis);
  }

  protected async afterGet(list: EclCrisis[]): Promise<EclCrisis[]> {
    const AllParameters = await this.getAllCrisisParameters(list);
    list.map(c => {
      c.params = AllParameters.filter(ap => ap.eclCrisis.objectId === c.objectId);
      return c;
    });

    return list;
  }

  /* Multicrise, non need to close current open crisis.
  protected beforeCreate(inputCrisis: EclCrisis): BehaviorSubject<string> {
    const closeCrisisProgress = new BehaviorSubject<string>('');
    this.commonStoreManager.eclCrisis.pipe(take(1)).subscribe(async crisis => {
      try {
        if (crisis && !crisis.closedAt) {
          closeCrisisProgress.next('Closing previous crisis...');
          crisis.closedAt = moment.utc().toDate();
          crisis.inProgress = false;
          crisis.isInPreparation = false;
          await this.saveCrisis(crisis);
          closeCrisisProgress.next('✅<br/>');
          closeCrisisProgress.complete();
        } else {
          closeCrisisProgress.next('No crisis to close<br/>');
          closeCrisisProgress.complete();
        }
      } catch (e) {
        closeCrisisProgress.error(e);
      }
    });
    return closeCrisisProgress;
  }

  */
  protected beforeCreate(inputCrisis: EclCrisis): BehaviorSubject<string> {
    const behavior = super.beforeCreate(inputCrisis);
    behavior.complete();
    return behavior;
  }

  protected async afterCreate(inputCrisis: EclCrisis, savedCrisis: EclCrisis, progress: BehaviorSubject<string>): Promise<EclCrisis> {
    try {
      // Copy new tags ref
      progress.next('<strong>✅ Copying tag...</strong>');
      await this.eclTagService.cloneRefTagNewCrisis(savedCrisis);
      progress.next('✅<br/>');
      // Copy new user function ref
      progress.next('<strong>✅ Copying user function...</strong>');
      await this.eclfunctionService.cloneRefUserFunctionNewCrisis(savedCrisis);
      //   await this.eclfunctionService.replaceAllUserFunctionFromREF();
      progress.next('✅<br/>');
      // Create crisis parameters
      progress.next('<strong>✅ Create crisis parameters...</strong>');
      await this.saveCrisisParameters(inputCrisis.params, savedCrisis);
      progress.next('✅<br/>');
      progress.next('<strong>✅ Crisis created !</strong>');
      progress.complete();
      // Send mails and sms to stakeholders

      this.eclMailService.sendNewCrisisEmails(savedCrisis, this.usersToNotifyByUser).then();
      this.eclSmsService.sendNewCrisisSms(savedCrisis, this.usersToNotifyByUser).then();
    } catch (e) {
      progress.error(e);
    }
    return savedCrisis;
  }

  private async automaticDecision(crisis: EclCrisis, open: boolean) {
    const decision = new EclDecision();

    // user role form universe
    const rolesTemp = await this.roleService.getUserCompanyRolesByUniverse('ECL');
    let roles = rolesTemp.filter(role => this.roleHasAnyPermission(role));
    //filter with ACL from crisis
    roles = rolesTemp.filter(role => this.roleHasAnyPermission(role) && crisis.companies.includes(role.company));
    const acl = await this.intersectAclFromUser(crisis);

    let func = await this.eclFunctionStoreManager.$selectedUserFunction.pipe(take(1)).toPromise();

    if (!func) {
      const funcs = await this.eclfunctionService.getFunctions();

      let currentUserFunc = await this.eclfunctionService.getCurrentUserFunctions(crisis);
      currentUserFunc = currentUserFunc.filter(value => !value.disabled);
      currentUserFunc.forEach(value => (value.function = funcs.find(value1 => value1.functionId == value.functionId)));

      if (currentUserFunc && currentUserFunc.length && currentUserFunc.length > 0) {
        func = currentUserFunc[0].function;
      }
    }

    decision.acl = acl;
    decision.message = this.$translate.instant(open ? 'ECL.DECISION_AUTO.REOPEN' : 'ECL.DECISION_AUTO.CLOSE');
    decision.createdBy = new HolUser(Parse.User.current());
    decision.crisis = crisis;
    decision.isPinned = true;
    decision.function = func;

    return this.eclDecisionService.create(decision, []);
  }

  private async intersectAclFromUser(holObjet: HolObject): Promise<Parse.ACL> {
    const rolesTemp = await this.roleService.getUserCompanyRolesByUniverse('ECL');
    let roles = rolesTemp.filter(role => this.roleHasAnyPermission(role));
    //filter with ACL from crisis
    roles = rolesTemp.filter(role => this.roleHasAnyPermission(role) && holObjet.companies.includes(role.company));

    const acl = new Parse.ACL();
    roles.forEach(r => {
      r.userWriteRoles.forEach(wr => {
        acl.setRoleWriteAccess(wr.parseRole, true);
      });
      r.writeRoles.forEach(wr => {
        acl.setRoleReadAccess(wr.parseRole, true);
      });
      r.readRoles.forEach(rr => {
        acl.setRoleReadAccess(rr.parseRole, true);
      });
    });
    return acl;
  }

  private roleHasAnyPermission(r: AclFilterRole, includeRead = true, includeWrite = true): boolean {
    return !!((includeRead && r.userReadRoles.length) || (includeWrite && r.userWriteRoles.length));
  }

  private async saveCrisisParameters(parameters: HolCrisisParameterValue[], savedCrisis: EclCrisis): Promise<EclSummary[]> {
    try {
      const ParseLogbook = Parse.Object.extend('ECLLogBook');
      const ParseEclFunction = Parse.Object.extend('ECLFunction');
      const refCrisis = new this.ParseCrisis({ id: savedCrisis.objectId });
      /*
       const parses = parameters.map(param => {
         const parseObject = new this.ParseHolCrisisParameterValue({id: param.objectId});
         parseObject.set('eclCrisis', refCrisis);
         parseObject.set('crisisParameterId', param.crisisParameterId);
         parseObject.set('value', param.value);
         parseObject.set('order', param.order);
         parseObject.set('isDefault', param.isDefault);

         if (savedCrisis.acl) {
           parseObject.setACL(savedCrisis.acl);
         }
         return parseObject;
       });
       */
      const funcs = await this.eclfunctionService.getFunctions();

      let currentUserFunc = await this.eclfunctionService.getCurrentUserFunctions(savedCrisis);
      const selectedUF = await this.eclFunctionStoreManager.$selectedUserFunction.pipe(take(1)).toPromise();

      currentUserFunc = currentUserFunc.filter(value => !value.disabled);
      currentUserFunc.forEach(value => (value.function = funcs.find(value1 => value1.functionId == value.functionId)));

      const logbooksToSave = [];
      const listParameter = await this.getCrisisTypeParameter(savedCrisis);
      listParameter.forEach(parameterBase => {
        const param = parameters.find(value => value.crisisParameterId == parameterBase.crisisParameterId);
        const createLogBook = new ParseLogbook();
        createLogBook.set('crisis', refCrisis);
        createLogBook.setACL(savedCrisis.acl);
        createLogBook.set('text', `${parameterBase.title} : ${param && param.value ? param.value : ''}`);
        createLogBook.set('isPinned', false);
        createLogBook.set('createdBy', Parse.User.current());

        if (currentUserFunc && currentUserFunc.length > 0) {
          createLogBook.set(
            'function',
            new ParseEclFunction({
              id: currentUserFunc[0].function.objectId,
            }),
          );
        }
        logbooksToSave.push(createLogBook);
      });

      let indexSummary = 0;
      const parseResultLogBook = await this.requestService.performSaveAllQuery(logbooksToSave);
      const summaries = parseResultLogBook.map(obj => {
        const summary = new EclSummary();
        summary.logBook = new EclLogbook(obj);
        summary.decision = null;
        summary.crisis = savedCrisis;
        summary.isDisplayInBriefing = false;
        summary.order = indexSummary;
        indexSummary++;
        return summary.parseToObject();
      });

      const parseResult = await this.requestService.performSaveAllQuery(summaries);
      return parseResult.map(res => new EclSummary(res));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  private async getCrisisTypeParameter(eclCrisis: EclCrisis) {
    const paramQuery = new Parse.Query(this.ParseCrisisTypeParameter);
    paramQuery.equalTo('crisisTypeId', eclCrisis.crisisTypeId);
    paramQuery.ascending('order');

    const parseResult = await this.requestService.performFindAllQuery(paramQuery);
    const list = parseResult ? parseResult.map(r => new HolCrisisParameter(r)) : [];
    return list;
  }

  private async getAllCrisisParameters(list: EclCrisis[] = []): Promise<HolCrisisParameterValue[]> {
    try {
      let crisisParametersQuery = new Parse.Query(this.ParseHolCrisisParameterValue);
      crisisParametersQuery.include('crisis');
      crisisParametersQuery.include('eclCrisis');
      crisisParametersQuery.include('erpCrisis');
      if (list.length) {
        const crisisQuery = new Parse.Query(this.ParseCrisis);
        crisisQuery.containedIn(
          'objectId',
          list.map(c => c.objectId),
        );
        // Before 2023-03 (March'23), crisis pointer (ECLCrisis) was stored in crisis attribute
        // Now we have 2 pointers: one for ECL crisis and one for ERP
        // To maintain compatibility with the past, we are doing the join with the 3 possible values: crisis, eclCrisis and erpCrisis
        const queryCrisis = new Parse.Query(this.ParseHolCrisisParameterValue);
        queryCrisis.matchesQuery('crisis', crisisQuery);
        const queryEclCrisis = new Parse.Query(this.ParseHolCrisisParameterValue);
        queryEclCrisis.matchesQuery('eclCrisis', crisisQuery);
        const queryErpCrisis = new Parse.Query(this.ParseHolCrisisParameterValue);
        queryErpCrisis.matchesQuery('erpCrisis', crisisQuery);

        const orQuery = Parse.Query.or(queryCrisis, queryEclCrisis, queryErpCrisis);
        crisisParametersQuery = Parse.Query.and(crisisParametersQuery, orQuery);
      }
      const parseResult = await this.requestService.performFindAllQuery(crisisParametersQuery);
      return parseResult.map(res => new HolCrisisParameterValue(res));
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
