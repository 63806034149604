'use strict';

const Rx = require('rxjs/Rx');

/**
 * @ngdoc function
 * @name gestiondecriseApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the gestiondecriseApp
 */
angular
  .module('gestiondecriseApp')
  .controller(
    'MainCtrl',
    function (
      $rootScope,
      $scope,
      $translate,
      $state,
      AuthenticationService,
      OptionsService,
      OCCOptionsService,
      ECLOptionsService,
      OPSOptionsService,
      RequestService,
      CommonStoreManager,
      ErpCrisisService,
      FunctionsStoreManager,
      ModalsService,
      CrisisService,
      ECLCrisisService,
      EclCrisisStoreManager,
      EclCrisisDirectorStoreManager,
      $mdDialog,
      UserService,
      EclFunctionStoreManager,
      ErpFunctionsService,
      CrisisStoreManager,
      HolOptionsService
    ) {
      let activateCrisisPopupSeenForThisInstance = false;

      $scope.erpCrisis = null;
      $scope.crisisStateLoading = false;
      $scope.isExternal = true;
      $scope.fullOverlayActive = false;
      $scope.canPrepareErpCrisis = false;
      $scope.canPrepareEclCrisis = false;
      $scope.canActivateErpCrisis = false;
      $scope.canActivateEclCrisis = false;
      $scope.canCloseErpCrisis = false;
      $scope.canCloseEclCrisis = true;
      $scope.companyLogoUrl = null;
      $scope.erpIsPresent = false;
      $scope.hasCrewAccess = $rootScope.accessRights.crew !== 'UNAUTHORIZED';
      $scope.hasOccAccess = $rootScope.accessRights.occ !== 'UNAUTHORIZED';
      $scope.hasEclAccess = $rootScope.accessRights.ecl !== 'UNAUTHORIZED';
      $scope.hasOpsAccess = $rootScope.accessRights.ops !== 'UNAUTHORIZED';
      $scope.hasExerciseAccess = $rootScope.accessRights.crisis !== 'UNAUTHORIZED';
      $scope.hasMccAccess = $rootScope.accessRights.mcc !== 'UNAUTHORIZED';
      $scope.hasGocAccess = $rootScope.accessRights.goc !== 'UNAUTHORIZED';
      $scope.eclTitle = $translate.instant('MENU.ECL');
      let isGettingLogout = false;
      let lastEclMessages = '';
      let lastErpMessages = '';
      // let ErpMessageData;
      // let EclMessageData;
      const ECLMessageCrisis = ECLCrisisService.getCurrentOrLastCrisis();
      // const CrisisObs = from(ECLMessageCrisis);
      const userEclFunctionsObs = EclFunctionStoreManager.$userFunctions;
      const userErpFunctions = UserService.getCurrentUserFunctions();
      // const userErpFunctionsObs = from(userErpFunctions);
      const EclAnnouncements = EclCrisisStoreManager.$eclAnnoucements;
      const ErpAnnouncements = CrisisStoreManager.lastCrisisAnnouncement;
      const actualUser = UserService.getCurrentUserObject();
      const regex1 = /@\w+([^\u0308\s]|$)/g; // finds words that start with @
      const regex2 = /#\w+([^\u0308\s]|$)/g;

      $scope.accordions = {
        occ: false,
        crisis: false,
        training: false
      };

      $scope.occDisplay = {
        checklist: true,
        dashboard: true,
        team: true,
        timeline: true
      };

      $scope.eclDisplay = {
        checklist: true,
        dashboard: true,
        team: true,
        timeline: true,
        map: true
      };

      $scope.opsDisplay = {
        checklist: true,
        dashboard: true,
        team: true,
        timeline: true
      };

      function getOCCOptions() {
        OCCOptionsService.get().then(occOptions => {
          if (occOptions && occOptions.hiddenButtons !== null) {
            const hiddenButtons = occOptions.hiddenButtons ? occOptions.hiddenButtons.split('|') : [];
            $scope.occDisplay.checklist = !hiddenButtons.includes('CHECKLIST');
            $scope.occDisplay.dashboard = !hiddenButtons.includes('DASHBOARD');
            $scope.occDisplay.team = !hiddenButtons.includes('TEAM');
            $scope.occDisplay.timeline = !hiddenButtons.includes('TIMELINE');
          }
        });
      }

      function getOPSOptions() {
        OPSOptionsService.get().then(opsOptions => {
          CommonStoreManager.updateOpsInfos({
            moduleIsActivated: opsOptions.moduleIsActivated
          });
          if (opsOptions && opsOptions.hiddenButtons !== null) {
            const hiddenButtons = opsOptions.hiddenButtons ? opsOptions.hiddenButtons.split('|') : [];
            $scope.opsDisplay.checklist = !hiddenButtons.includes('CHECKLIST');
            $scope.opsDisplay.dashboard = !hiddenButtons.includes('DASHBOARD');
            $scope.opsDisplay.team = !hiddenButtons.includes('TEAM');
            $scope.opsDisplay.timeline = !hiddenButtons.includes('TIMELINE');
          }
        });
      }

      function getECLOptions() {
        ECLOptionsService.get(true).then(eclOptions => {
          CommonStoreManager.updateEclInfos({
            moduleName: eclOptions.moduleName,
            moduleIsActivated: eclOptions.moduleIsActivated,
            occEventId: eclOptions.occEvent && eclOptions.occEvent.id,
            eclOptionsId: eclOptions.objectId
          });

          if (eclOptions && eclOptions.hiddenButtons !== null) {
            const hiddenButtons = eclOptions.hiddenButtons ? eclOptions.hiddenButtons.split('|') : [];
            $scope.eclDisplay.checklist = !hiddenButtons.includes('CHECKLIST');
            $scope.eclDisplay.dashboard = !hiddenButtons.includes('DASHBOARD');
            $scope.eclDisplay.team = !hiddenButtons.includes('TEAM');
            $scope.eclDisplay.timeline = !hiddenButtons.includes('TIMELINE');
            $scope.eclDisplay.map = !hiddenButtons.includes('MAP');
          }
        });
      }

      getOCCOptions();
      getECLOptions();
      getOPSOptions();

      function normalizeWords(words) {
        return words.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      }

      function formatUser(user) {
        const search = / /gi;
        const concatFirstName = user.firstName.replace(search, '_');
        const concatLastName = user.lastName.replace(search, '_');
        return normalizeWords('@' + concatFirstName + '__' + concatLastName);
      }

      function formatFonction(fonction) {
        const search = / /gi;
        const concatWords = fonction.replace(search, '_');
        return normalizeWords('#' + concatWords);
      }

      const formatedUser = formatUser(actualUser);

      function includesUserFunction(msgFunct, userFunct) {
        let included = false;
        if (msgFunct !== undefined && msgFunct.length > 0) {
          msgFunct.forEach(f => {
            if (userFunct.includes(f)) {
              included = true;
            }
          });
        }
        return included;
      }

      function showPopupMessage(message, crisis, module) {
        const holOptions = {
          title: $translate.instant(`${module}.MODALS.GLOBAL_ANNOUNCEMENT.MODAL_TITLE`),
          htmlContent: message.contentText,
          crisisTitle: crisis.mainTitle,
          clickOutsideToClose: false
        };
        $mdDialog.show($mdDialog.holMessageConfirm(holOptions));
      }

      function timeSinceAnnouncement(createdAt) {
        let timeDiff = 0;
        timeDiff = Math.abs(new Date() - createdAt);
        return timeDiff < 1800000 ? true : false;
      }

      //Get ECL messages
      const EclMessages = Rx.Observable.combineLatest([EclAnnouncements, ECLMessageCrisis, userEclFunctionsObs]).subscribe(eclData => {
        let eclUserFunctions = [];
        let eclCrisis = [];
        let eclDestinataires = [];
        let eclMsgFunctions = [];
        let message = '';
        if (eclData && eclData[0].length > 0 && lastEclMessages !== eclData[0][0].objectId) {
          message = normalizeWords(eclData[0][0].contentText);
          eclDestinataires = message.match(regex1);
          eclMsgFunctions = message.match(regex2);
        }
        eclCrisis = eclData[1];
        eclData[2].forEach(f => {
          eclUserFunctions.push(formatFonction(f.title));
        });
        if (
          eclData[0][0] !== undefined &&
          eclData[0][0].createdBy.userId !== actualUser.userId &&
          timeSinceAnnouncement(eclData[0][0].createdAt) &&
          ((eclData[0] && eclData[0][0] && eclDestinataires && eclDestinataires.includes(formatedUser)) ||
            (eclMsgFunctions && includesUserFunction(eclMsgFunctions, eclUserFunctions)))
        ) {
          if (lastEclMessages !== eclData[0][0]) {
            lastEclMessages = eclData[0][0].objectId;
            showPopupMessage(eclData[0][0], eclCrisis, 'ECL');
          }
        }
      });

      //Get ERP messages
      const ErpMessages = Rx.Observable.combineLatest([ErpAnnouncements, ECLMessageCrisis, userErpFunctions]).subscribe(erpData => {
        let erpUserFunctions = [];
        let erpCrisis = [];
        let erpDestinataires = [];
        let erpMsgFunctions = [];
        let message = '';

        if (erpData && erpData[0] && lastErpMessages !== erpData[0].objectId) {
          message = normalizeWords(erpData[0].contentText);
          erpDestinataires = message.match(regex1);
          erpMsgFunctions = message.match(regex2);
        }
        erpCrisis = erpData[1];
        erpData[2].forEach(f => {
          erpUserFunctions.push(formatFonction(f.title));
        });
        if (
          (erpData[0] &&
            timeSinceAnnouncement(erpData[0].createdAt) &&
            erpData[0].createdBy.userId !== actualUser.userId &&
            erpData[0] &&
            erpDestinataires &&
            erpDestinataires.includes(formatedUser)) ||
          (erpMsgFunctions && includesUserFunction(erpMsgFunctions, erpUserFunctions))
        ) {
          if (lastErpMessages !== erpData[0]) {
            lastErpMessages = erpData[0].objectId;
            showPopupMessage(erpData[0], erpCrisis, 'ERP');
          }
        }
      });

      const getVariablesFromCurrentCrisis = (module = '') => {
        const erpCrisis = $scope.erpCrisis;
        const eclCrisis = $scope.eclCrisis;
        let chosenCrisis = null;
        let service = null;
        let path = '';
        if (module === 'ERP') {
          chosenCrisis = erpCrisis;
          service = ErpCrisisService;
          path = 'app.crisis.dashboard';
        } else if (module === 'ECL') {
          chosenCrisis = eclCrisis;
          service = ECLCrisisService;
          path = 'app.ecl.dashboard';
        }
        return { chosenCrisis, service, path };
      };

      let crisisActivationModal;
      let crisisPreparationModal;

      function init() {
        CommonStoreManager.commonState.subscribe(common => {
          $scope.eclModuleInfos = {
            moduleName: common.eclInfos.moduleName,
            moduleIsActivated: common.eclInfos.moduleIsActivated
          };
          $scope.opsModuleInfos = {
            moduleIsActivated: common.opsInfos.moduleIsActivated
          };
          if ($scope.eclModuleInfos.moduleName && $scope.eclModuleInfos.moduleName.trim().length > 0) {
            $scope.eclTitle = $scope.eclModuleInfos.moduleName;
          }
          $scope.isExternal = common.currentUser && common.currentUser.isExternal;
        });

        Rx.Observable.combineLatest([
          CommonStoreManager.currentUserCrisisRolesRef,
          EclCrisisDirectorStoreManager.currentUserEclCrisisRolesRef,
          CommonStoreManager.crisis,
          CommonStoreManager.eclCrisis,
          EclCrisisStoreManager.$eclSelectedCrisis,
          EclCrisisStoreManager.$eclCrisisTypes
        ]).subscribe(([erpRolesRef, eclRolesRef, erpCrisis, currentEclCrisis, eclSelectedCrisis, eclCrisisTypes]) => {
          /* CRISIS PERMISSIONS
            - Activate crisis: Be director OR (Be initializer and config allows that initializers activate the crisis (for ECL, crisis are directly activated on creation)
            - Prepare crisis: Be director OR be initializer (ERP only)
            - Close crisis: Be director (ERP and ECL).
           */
          $scope.canActivateErpCrisis =
            erpRolesRef.isInCrisisDirectorTeam || (erpRolesRef.isInCrisisInitializerTeam && OptionsService.crisisInitializerCanActivate());
          $scope.canActivateEclCrisis =
            eclRolesRef.isInCrisisDirectorTeam ||
            (eclRolesRef.isInCrisisInitializerTeam && ECLOptionsService.crisisInitializerCanActivate());
          $scope.canPrepareErpCrisis = erpRolesRef.isInCrisisInitializerTeam || erpRolesRef.isInCrisisDirectorTeam;
          $scope.canPrepareEclCrisis = eclRolesRef.isInCrisisInitializerTeam || eclRolesRef.isInCrisisDirectorTeam;
          $scope.canCloseEclCrisis = eclRolesRef.isInCrisisInitializerTeam;
          // Previously on ERP, to close a crisis you must be director and holder of the director function
          // $scope.canCloseErpCrisis = !!erpRoles.crisisDirector.holderFor.length;
          $scope.canCloseErpCrisis = erpRolesRef.isInCrisisDirectorTeam;

          if (erpCrisis && erpCrisis.objectId) {
            $scope.erpCrisis = erpCrisis;
            $scope.crisisShoulBeDisplayOnMenu =
              erpCrisis.isInPreparation || erpCrisis.inProgress || OptionsService.crisisShouldBeDisplayOnMenu(erpCrisis);
          }
          if (currentEclCrisis && currentEclCrisis.objectId) {
            $scope.eclCrisis = currentEclCrisis;
          }
          $scope.eclCrisisTypes = eclCrisisTypes;

          if (eclSelectedCrisis || currentEclCrisis) {
            if ($scope.eclSelectedCrisis) {
              console.log('Ici ? ');
              const crisisType = (eclCrisisTypes || []).find(ct => ct.crisisTypeId === eclSelectedCrisis.crisisTypeId);
              $scope.eclSubtitle = crisisType && crisisType.title;
            } else {
              $scope.eclSubtitle = '';
            }
          } else {
            $scope.eclSubtitle = '';
          }
        });

        EclCrisisStoreManager.$eclSelectedCrisis.subscribe(eclCrisis => {
          $scope.eclSelectedCrisis = eclCrisis;

          if (eclCrisis) {
            const crisisType = ($scope.eclCrisisTypes || []).find(ct => ct.crisisTypeId === eclCrisis.crisisTypeId);
            $scope.eclSubtitle = crisisType && crisisType.title;
          } else {
            $scope.eclSubtitle = '';
          }
        });

        CommonStoreManager.erpCrisisInPreparation.subscribe(() => {
          if ($scope.canActivateErpCrisis && !CrisisService.crisisInPreparation && !crisisPreparationModal) {
            $scope.showConfirmCrisisActivation('ERP');
          }
          CrisisService.crisisInPreparation = false;
        });

        CommonStoreManager.eclCrisisInPreparation.subscribe(() => {
          if ($scope.canActivateEclCrisis && !ECLCrisisService.crisisInPreparation && !crisisPreparationModal) {
            $scope.showConfirmCrisisActivation('ECL');
          }
          ECLCrisisService.crisisInPreparation = false;
        });

        CommonStoreManager.erpCrisisActivated.subscribe(() => {
          if (!CrisisService.crisisInCreation && !crisisActivationModal) {
            const translationKey = $scope.erpCrisis.isTraining ? 'EXERCISE' : 'CRISIS';
            crisisActivationModal = ModalsService.openAlertModal({
              title: $translate.instant('ERP.MODALS.ALERT_' + translationKey + '_CREATED.TITLE'),
              content: $translate.instant('ERP.MODALS.ALERT_' + translationKey + '_CREATED.CONTENT')
            }).then(() => {
              crisisActivationModal = null;
              $state.go('app.crisis.dashboard', {}, { reload: true });
            });
          }
          CrisisService.crisisInCreation = false;
        });

        /*
        CommonStoreManager.eclCrisisActivated.subscribe(() => {

          if (!ECLCrisisService.crisisInCreation && !crisisActivationModal) {
            const translationKey = $scope.eclCrisis.isTraining ? 'EXERCISE' : 'CRISIS';
            crisisActivationModal = ModalsService.openAlertModal({
              title: $translate.instant('ECL.MODALS.ALERT_' + translationKey + '_CREATED.TITLE'),
              content: $translate.instant('ECL.MODALS.ALERT_' + translationKey + '_CREATED.CONTENT')
            }).then(() => {
              crisisActivationModal = null;
              $state.go('app.ecl.dashboard', {}, {reload: true});
            });
          }
          CrisisService.crisisInCreation = false;

        });
        */

        let lastCrisisSave;
        CommonStoreManager.lastEclCrisis.subscribe(lastCrisis => {
          if (!lastCrisisSave) {
            lastCrisisSave = lastCrisis;
          } else if (!ECLCrisisService.crisisInCreation && !crisisActivationModal && lastCrisisSave) {
            if (lastCrisisSave && lastCrisisSave.objectId && lastCrisisSave.objectId !== lastCrisis.objectId) {
              const translationKey = lastCrisis.isTraining ? 'EXERCISE' : 'CRISIS';
              crisisActivationModal = ModalsService.openConfirmModal({
                title: $translate.instant('ECL.MODALS.ALERT_' + translationKey + '_CREATED.TITLE'),
                content: $translate.instant('ECL.MODALS.ALERT_' + translationKey + '_CREATED.CONTENT', {
                  userName: lastCrisis.createdBy.fullName,
                  title: lastCrisis.mainTitle,
                  subTitle: lastCrisis.subTitle
                }),
                question: $translate.instant('ECL.MODALS.ALERT_' + translationKey + '_CREATED.QUESTION')
              }).then(res => {
                crisisActivationModal = null;
                if (res) {
                  EclCrisisStoreManager.changeSelectedCrisis(lastCrisis);
                  $state.go('app.ecl.dashboard', {}, { reload: true });
                }
              });
            }
            lastCrisisSave = lastCrisis;
          }
          CrisisService.crisisInCreation = false;
        });
        $scope.companyLogoUrl = OptionsService.getCompanyLogoUrl();

        HolOptionsService.get().then(result => {
          if (result.activeModules.includes('ERP')) {
            $scope.erpIsPresent = true;
          } else {
            $scope.erpIsPresent = false;
          }
        });
      }

      init();

      $scope.isState = function (stateId) {
        return $state.current.name.indexOf(stateId) !== -1;
      };

      $scope.isCrisisSelected = function () {
        return $scope.eclSelectedCrisis !== null;
      };

      $scope.closeCurrentCrisis = (ev, module) => {
        const { chosenCrisis, service } = getVariablesFromCurrentCrisis(module);
        // Appending dialog to document.body to cover sidenav in docs app
        ModalsService.openConfirmModal({
          title: $translate.instant(`CRISIS.MODAL.CLOSE_CRISIS.${module}.CONFIRM_TITLE`),
          content: $translate.instant(`CRISIS.MODAL.CLOSE_CRISIS.${module}.CONFIRM_CONTENT`),
          modalType: 'info',
          targetEvent: ev
        }).then(res => {
          if (res) {
            // Close the current crisis
            $scope.crisisStateLoading = true;
            return service
              .closeCrisis(chosenCrisis)
              .then(closedCrisis => {
                if (module === 'ERP') {
                  CommonStoreManager.initErpCrisis(closedCrisis);
                } else if (module === 'ECL') {
                  // When closing a crisis, we should update 2 stores:
                  // CommonStoreManager contains the current crisis, and it should be updated
                  // EclCrisisStoreManager contains the crisis list and the selected crisis and should be updated too.
                  CommonStoreManager.initEclCrisis(closedCrisis);
                  EclCrisisStoreManager.closeCrisis(closedCrisis);
                }
              })
              .finally(() => {
                // Re-start the polling
                $scope.crisisStateLoading = false;
              });
          }
        });
      };

      $rootScope.hideFullOverlay = () => ($scope.fullOverlayActive = false);

      $rootScope.showFullOverlay = () => ($scope.fullOverlayActive = true);

      $scope.showConfirmCrisisActivation = (event, module) => {
        activateCrisisPopupSeenForThisInstance = true;
        const { chosenCrisis, service, path } = getVariablesFromCurrentCrisis(module);

        const crisisType = chosenCrisis.isTraining ? 'EXERCICE' : 'CRISIS';
        if (module) {
          crisisPreparationModal = ModalsService.openConfirmModal({
            title: $translate.instant(`${module}.MODALS.ACTIVATE_${crisisType}.TITLE`),
            content: $translate.instant(`${module}.MODALS.ACTIVATE_${crisisType}.CONTENT`),
            question: $translate.instant(`${module}.MODALS.ACTIVATE_${crisisType}.QUESTION`)
          }).then(res => {
            crisisPreparationModal = null;
            if (res === true) {
              if (module) {
                service.activateByCrisisDirector(chosenCrisis).then(() => {
                  $state.go(path, {}, { reload: true });
                });
              }
              activateCrisisPopupSeenForThisInstance = false;
            } else if (res === false) {
              $scope.closeCurrentCrisis(null, module);
              activateCrisisPopupSeenForThisInstance = false;
            }
          });
        }
      };

      const userLogoutSub = RequestService.forceLogout.subscribe(() => {
        if (!isGettingLogout) {
          isGettingLogout = true;
          AuthenticationService.logout(true).finally(() => (isGettingLogout = false));
        }
      });

      /**
       * Destroy the scope.
       */
      $scope.$on('$destroy', () => {
        console.log('[MainCtlr] $destroy');
        // Remove all listeners on $rootScope
        userLogoutSub.unsubscribe();
        EclMessages.unsubscribe();
        ErpMessages.unsubscribe();
        // ErpMessageData.unsubscribe();
        //  EclMessageData.unsubscribe();
      });
    }
  );
