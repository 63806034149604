import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { Component, Inject, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../login/services/authentication.service';
import { CommonStoreManager } from '../../store/common.store-manager';
import { Subscription, combineLatest, BehaviorSubject } from 'rxjs';
import { HolCrisis } from '../../models/hol-crisis';
import { UserService } from '../../services/user.service';
import { HolUserWithRoles } from '../../../admin/pages/admin-roles/admin-roles.component';
import { HolHelperUserFunctionService } from '../../services/hol-helper-user-function.service';
import { OclHelperLinkService } from '../../../ocl/services/ocl-helper-link.service/ocl-helper-link.service';
import { OclHelperLink } from '../../../ocl/models/ocl-helper-link.model';
import { take } from 'rxjs/operators';
import { HelperService } from '../../services/helper.service';
import { HOLOptions, HolOptionsService } from '../../services/hol-options.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() context: string;
  @Input() canAddBreakingNews: boolean;
  @Input() indicators = false;
  breakingNews;
  username = '';
  showIndicators: boolean;
  accessRights: any;
  showChat: boolean;
  public breakingNewsPanelExpanded = false;
  loading = false;
  users: HolUserWithRoles[];
  currentUserIsAdmin: boolean;
  currentUser: any;
  userFunctionsLoading = false;
  isAdminPage: boolean = this.$state.current.name === 'app.admin.dashboard';
  @Output() closeExternalPanels: EventEmitter<boolean> = new EventEmitter();
  public bufferHelperLinkId: string[];
  public helperLinkList: OclHelperLink[];
  isSuperAdmin: boolean;
  public isDevMode: boolean = this.helperService.isDevMode();
  public menuItem: Adminbutton[] = [];
  private storeSub: Subscription;
  private componentSub: Subscription;
  private crisis: HolCrisis;
  holOptions: HOLOptions;

  constructor(
    @Inject('$rootScope') public $rootScope,
    @Inject('$state') private $state,
    @Inject('$translate') private readonly $translate,
    private readonly userService: UserService,
    private authenticationService: AuthenticationService,
    private commonStoreManager: CommonStoreManager,
    private readonly dialog: MatDialog,
    private holHelperUserFunctionService: HolHelperUserFunctionService,
    protected helperLinkListService: OclHelperLinkService,
    private helperService: HelperService,
    private readonly holOptionsService: HolOptionsService,
  ) {
    this.username = userService.getCurrentUser();
    this.accessRights = $rootScope.accessRights;

    try {
      this.helperLinkListService.getLinks(this.bufferHelperLinkId, !!this.bufferHelperLinkId).then(l => {
        this.helperLinkList = l;
        this.bufferHelperLinkId = this.helperLinkList.map(el => el.objectId);
      });
    } catch (e) {
      //console.log(e);
    }
  }

  protected _closeOtherPanels = new BehaviorSubject<boolean>(false);

  get closeOtherPanels() {
    return this._closeOtherPanels.getValue();
  }

  @Input()
  set closeOtherPanels(value) {
    this._closeOtherPanels.next(value);
  }

  public goToTemplateHelper(link): void {
    window.open(link, '_blank');
  }

  async ngOnInit() {
    this.loading = true;
    await this.checkIfSuperAdmin();
    this.storeSub = combineLatest([this.commonStoreManager.crisis, this.commonStoreManager.breakingNews]).subscribe(
      ([crisis, breakingNews]) => {
        this.crisis = crisis;
        this.breakingNews = breakingNews;
      },
    );

    if (this.context !== 'crisis' && this.context !== 'mcc' && this.context !== 'goc') {
      this.userService.isCurrentUserInTheChatTeam().then(isInTeam => {
        this.showChat = isInTeam;
      });
    }

    this.componentSub = this._closeOtherPanels.subscribe(value => {
      if (this.breakingNewsPanelExpanded && value) {
        this.breakingNewsPanelExpanded = false;
      }
    });

    this.currentUserIsAdmin = await this.hasAccess();
    this.holOptions = await this.holOptionsService.get(true);
    const isECLActive = this.holOptions.activeModules.some(value => value.toUpperCase() === 'ECL');

    this.menuItem = [
      {
        label: this.$translate.instant('SETTINGS.USERS.TITLE'),
        click: 'app.admin.management',
        isVisible: this.currentUserIsAdmin,
      },
      {
        label: this.$translate.instant('SETTINGS.USER_RIGHTS_MANAGEMENT.TITLE'),
        click: 'app.admin.roles',
        isVisible: this.currentUserIsAdmin,
      },
      {
        label: this.$translate.instant('SETTINGS.OPTIONS.TITLE'),
        click: 'app.admin.options',
        isVisible: this.currentUserIsAdmin,
      },
      {
        label: this.$translate.instant('SETTINGS.ECL_OPTIONS.TITLE'),
        click: 'app.admin.ecl-options',
        isVisible: this.currentUserIsAdmin && isECLActive,
      },
      {
        label: this.$translate.instant('SETTINGS.CRISIS_TYPE_MANAGEMENT.TITLE'),
        click: 'app.admin.crisis-types',
        isVisible: this.currentUserIsAdmin,
      },
      {
        label: this.$translate.instant('SETTINGS.TAGS.TITLE'),
        click: 'app.admin.tags',
        isVisible: this.currentUserIsAdmin,
      },
      {
        label: this.$translate.instant('SETTINGS.ADVANCED_ADMINISTRATION.TITLE'),
        click: 'app.admin.dashboard',
        isVisible: this.isSuperAdmin,
      },
      {
        label: this.$translate.instant('SETTINGS.PASSWORD.TITLE'),
        click: 'app.admin.password',
        isVisible: this.holOptions.showPasswordAdminPage,
      },
    ];

    this.menuItem = this.menuItem.filter(value => value.isVisible);
  }

  async hasAccess() {
    try {
      return await this.authenticationService.isAdmin();
    } catch (e) {
      return false;
    }
  }

  toggleSideNav() {
    this.$rootScope.isSideNavOpen = !this.$rootScope.isSideNavOpen;
  }

  ngOnDestroy(): void {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.componentSub) {
      this.componentSub.unsubscribe();
    }
  }

  logout(ev) {
    const confirmOptions = {
      title: this.$translate.instant('SETTINGS.CONFIRM_LOGOUT'),
      targetEvent: ev,
      htmlContent: '',
    };
    this.userService
      .getCurrentUserFunctions()
      .then(functions => {
        functions = functions.filter(f => f.isHolder);
        if (functions.length > 0) {
          const functionsStr = functions.map(f => f.shortTitle).join(', ');
          confirmOptions.htmlContent = this.$translate.instant('SETTINGS.LOGOUT_WARNING_HOLDER', { functions: functionsStr });
        }
      })
      .finally(() => {
        this.dialog
          .open(ConfirmationModalComponent, {
            data: {
              modalTitle: confirmOptions.title,
              modalContent: confirmOptions.htmlContent,
              modalQuestion: this.$translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
            },
          })
          .afterClosed()
          .toPromise()
          .then(confirm => {
            if (confirm) {
              this.authenticationService.logout(this.crisis && this.crisis.inProgress).then();
            }
          });
      });
  }

  addBreakingNews() {
    this.breakingNewsPanelExpanded = !this.breakingNewsPanelExpanded;
    if (this.breakingNewsPanelExpanded) {
      this.closeExternalPanels.emit(true);
    }
  }

  toggleIndicatorPanel() {
    this.showIndicators = !this.showIndicators;
  }

  goToPage(pageName: string) {
    this.$state.go(pageName);
  }

  goToCrisisTypeManagement() {
    this.$state.go('app.admin.crisis-types');
  }

  async checkIfSuperAdmin() {
    const helperUserFunctions = await this.holHelperUserFunctionService.getAll();
    await this.commonStoreManager.currentUser.pipe(take(1)).subscribe(user => {
      this.isSuperAdmin = helperUserFunctions.some(helperUserFunction => {
        return helperUserFunction.username === user.username;
      });
    });
  }
}

export interface Adminbutton {
  label: string;
  click: string;
  isVisible: boolean;
}
