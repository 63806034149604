<div class="hol-box global-instruction-box">
  <div class="hol-box__header">
    <div class="header-title-container title-box-occ">
      <div *ngIf="isClosable">
        <button (click)="onClose.emit()" class="btn-close">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <span class="word-break">{{ moduleConfig.config.translateKey + '.DASHBOARD.GLOBAL_INSTRUCTION.TITLE' | translate }}</span
      >&nbsp;
      <small *ngIf="isReadOnly || localHourOnly" class="hol-box__subtitle">
        {{ moduleConfig.config.translateKey + '.COMMON.TIMES_LT' | translate }}</small
      >
      <small *ngIf="!isReadOnly && !localHourOnly" class="hol-box__subtitle">
        {{ moduleConfig.config.translateKey + '.COMMON.TIMES_UTC' | translate }}</small
      >
      <div class="fill-space"></div>
      <button
        (click)="openGroupModal()"
        *ngIf="(userCanEditCards || userCanEditCards === undefined) && !isReadOnly && globalInstructionItems.length"
        aria-label="Add new group"
        class="md-icon-button md-raised hol-invert-no-border"
        mat-icon-button=""
        tabindex="-1"
      >
        <img src="assets/images/group-attach.svg" />
      </button>
      <button
        (click)="openAddModal($event)"
        *ngIf="(userCanEditCards || userCanEditCards === undefined) && !isReadOnly"
        aria-label="Add Global Instruction"
        class="md-icon-button md-raised"
        mat-icon-button=""
      >
        <i class="fa fa-plus"></i>
      </button>
    </div>
    <div class="header-action-container">
      <mat-checkbox *ngIf="enableViewGroup" [(ngModel)]="isSetGroups" [disabled]="isReadOnly" [labelPosition]="'after'" color="primary">
        <span class="checkbox-label">{{
          moduleConfig.config.translateKey + '.DASHBOARD.GLOBAL_INSTRUCTION.GROUP_CHECKBOX' | translate
        }}</span>
      </mat-checkbox>
    </div>
  </div>
  <div class="hol-box__content">
    <div class="occ-table">
      <div class="iscroll-wrapper" iscroll iscroll-indicator iscroll-instance="indicator">
        <div class="iscroll-scroller">
          <ul *ngIf="globalInstructionGrouped.length; else noResultGlobalInstructionTemplate" class="hol-list">
            <ng-container
              *ngIf="
                globalInstructionGrouped
                  | occSearchDashboard : searchCriteriasForPipe : context
                  | aclGroupFilter : RolesService.companiesRolesFilter as globalInstructionItemsFilteredFromPipe
              "
            >
              <ng-container *ngFor="let globalInstructionGroup of globalInstructionItemsFilteredFromPipe; trackBy: trackByFn">
                <li
                  *ngIf="
                    globalInstructionGroup.displayInGroupViewStatus === 'ALWAYS_VISIBLE' ||
                    (isSetGroups && globalInstructionGroup.objectId) ||
                    (!isSetGroups && globalInstructionGroup.displayInGroupViewStatus === 'NOT_VISIBLE_IN_GROUP_VIEW')
                  "
                  [@EnterLeave]="'flyIn'"
                >
                  <app-ocl-container-group-item
                    [globalInstructionItems]="globalInstructionGroup"
                    [globalInstructionToDisplay]="globalInstructionGroup.getMostRecentItem"
                    [group]="globalInstructionGroup"
                    [groupsFromApi]="groupsFromApi"
                    [isReadOnly]="isReadOnly"
                    [notifications]="notifications"
                    [type]="'GLOBAL_INSTRUCTION'"
                    [userCanEditCards]="userCanEditCards"
                    [utcDisplay]="utcDisplay"
                  ></app-ocl-container-group-item>
                </li>
              </ng-container>
              <div *ngIf="globalInstructionItemsFilteredFromPipe.length === 0" class="no-result">
                <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.GLOBAL_INSTRUCTION.NO_RESULT' | translate }}</span>
              </div>
            </ng-container>
          </ul>

          <ng-template #noResultGlobalInstructionTemplate>
            <ul class="hol-list">
              <div class="no-result">
                <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.GLOBAL_INSTRUCTION.NO_RESULT' | translate }}</span>
              </div>
            </ul>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
